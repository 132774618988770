// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require popper
//= require bootstrap-sprockets

require("@rails/ujs").start()
// require("turbolinks").start()
require("channels")

require("jquery")
require("jquery-ui")

import "./modules/bootstrap";
import "./modules/feather";
import "./modules/moment";
import "./modules/sidebar";
import "./modules/splash";
import "./modules/theme";
import "./modules/toastr";
import "./modules/user-agent";
//import "./modules/prismjs";
import  'prism/prism'

import 'css/scss/modern'
import 'css/prism'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
